"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMBINED_TABLE_CONFIGS = exports.ENABLE_PARTIAL_DATE_TYPE_COLUMN_MAPPING_HEADER_HASHES = exports.FORCE_DETECT_SKEW_HEADER_HASHES = exports.EXEMPT_FORCE_ADEM_HEADER_HASHES = exports.SHOULD_SPLICE_IN_AZURE_HEADER_HASHES = exports.RASTERIZE_HEADER_HASHES = exports.USE_AZURE_AS_PRIMARY_SOURCE_HEADER_HASHES = exports.RASTERIZE_PDF_CONFIG_OPTIONS = exports.XLXS_TO_PDF_CONFIG_OPTIONS = exports.SHOULD_NOT_CONVERT_XLXS_TO_PDF = exports.SHOULD_CONVERT_XLXS_TO_PDF = exports.SHOULD_CONVERT_XLS_TO_XLSX = exports.ORG_ID_DICT = exports.Organization = exports.HeaderHash = void 0;
var HeaderHash;
(function (HeaderHash) {
    HeaderHash[HeaderHash["UHC_SPECIALITY"] = 364688061] = "UHC_SPECIALITY";
    HeaderHash[HeaderHash["MGIS"] = -2076667607] = "MGIS";
    HeaderHash[HeaderHash["HUMANA_T1"] = 1652071304] = "HUMANA_T1";
    HeaderHash[HeaderHash["HUMANA_T2"] = -1260529643] = "HUMANA_T2";
    HeaderHash[HeaderHash["HUMANA_T3"] = 1358398138] = "HUMANA_T3";
    HeaderHash[HeaderHash["HUMANA_T4"] = -1387871327] = "HUMANA_T4";
    HeaderHash[HeaderHash["HUMANA_T5"] = -1269683417] = "HUMANA_T5";
    HeaderHash[HeaderHash["NATIONWIDE"] = 1714279868] = "NATIONWIDE";
    HeaderHash[HeaderHash["BCBS_IL"] = -923897014] = "BCBS_IL";
    HeaderHash[HeaderHash["BCBS_KS"] = 1769669924] = "BCBS_KS";
    HeaderHash[HeaderHash["BCBS_NE"] = -1203110343] = "BCBS_NE";
    HeaderHash[HeaderHash["BCBS_TX"] = -583990251] = "BCBS_TX";
    HeaderHash[HeaderHash["BCBS_MN"] = -1038590186] = "BCBS_MN";
    HeaderHash[HeaderHash["BCBS_MS"] = 1956691430] = "BCBS_MS";
    HeaderHash[HeaderHash["METLIFE_T1"] = 754607485] = "METLIFE_T1";
    HeaderHash[HeaderHash["METLIFE_T2"] = 1263960642] = "METLIFE_T2";
    HeaderHash[HeaderHash["VSP"] = -1646598050] = "VSP";
    HeaderHash[HeaderHash["THE_STANDARD_V1_T1"] = 178379030] = "THE_STANDARD_V1_T1";
    HeaderHash[HeaderHash["THE_STANDARD_V1_T2"] = 409585348] = "THE_STANDARD_V1_T2";
    HeaderHash[HeaderHash["THE_STANDARD_V2"] = -1751604346] = "THE_STANDARD_V2";
    HeaderHash[HeaderHash["THE_STANDARD_V3"] = -690645805] = "THE_STANDARD_V3";
    HeaderHash[HeaderHash["DELTA_DENTAL_T1"] = -644566382] = "DELTA_DENTAL_T1";
    HeaderHash[HeaderHash["EYE_MED"] = 1471135005] = "EYE_MED";
    HeaderHash[HeaderHash["AFLAC_T1"] = 1185514363] = "AFLAC_T1";
    HeaderHash[HeaderHash["AFLAC_T2"] = 1998491959] = "AFLAC_T2";
    HeaderHash[HeaderHash["VOYA"] = -905843480] = "VOYA";
    HeaderHash[HeaderHash["CYPRESS"] = -1485606183] = "CYPRESS";
    HeaderHash[HeaderHash["CHUBB"] = -449409061] = "CHUBB";
    HeaderHash[HeaderHash["HARTFORD_T1"] = 2067226266] = "HARTFORD_T1";
    HeaderHash[HeaderHash["HARTFORD_T2"] = 1472661178] = "HARTFORD_T2";
    HeaderHash[HeaderHash["HARTFORD_T3"] = -126878116] = "HARTFORD_T3";
    HeaderHash[HeaderHash["HARTFORD_T4"] = -114451705] = "HARTFORD_T4";
    HeaderHash[HeaderHash["HARTFORD_T5"] = 1243769586] = "HARTFORD_T5";
    HeaderHash[HeaderHash["HARTFORD_T6"] = 1803291341] = "HARTFORD_T6";
    HeaderHash[HeaderHash["HARTFORD_T7"] = 1980146442] = "HARTFORD_T7";
    HeaderHash[HeaderHash["HARTFORD_T8"] = 198583394] = "HARTFORD_T8";
    HeaderHash[HeaderHash["HARTFORD_T9"] = -25847049] = "HARTFORD_T9";
    HeaderHash[HeaderHash["HARTFORD_T10"] = -328384243] = "HARTFORD_T10";
    HeaderHash[HeaderHash["HARTFORD_T11"] = -79252472] = "HARTFORD_T11";
    HeaderHash[HeaderHash["HEALTH_NET_LIFE"] = -1186330054] = "HEALTH_NET_LIFE";
    HeaderHash[HeaderHash["AMERICAN_BANKERS"] = -1584517970] = "AMERICAN_BANKERS";
    HeaderHash[HeaderHash["ACUITY"] = -298696476] = "ACUITY";
    HeaderHash[HeaderHash["PRINCIPAL"] = 1103159240] = "PRINCIPAL";
    HeaderHash[HeaderHash["GEOVERA_T1"] = -668630607] = "GEOVERA_T1";
    HeaderHash[HeaderHash["GEOVERA_T2"] = 1461579997] = "GEOVERA_T2";
    HeaderHash[HeaderHash["UNUM_T1"] = 878977887] = "UNUM_T1";
    HeaderHash[HeaderHash["UNUM_T2"] = -285029899] = "UNUM_T2";
    HeaderHash[HeaderHash["ANTHEM_ELEVATE_T1"] = 2000063343] = "ANTHEM_ELEVATE_T1";
    HeaderHash[HeaderHash["ANTHEM_ELEVATE_T2"] = -1826563154] = "ANTHEM_ELEVATE_T2";
    HeaderHash[HeaderHash["ANTHEM_ELEVATE_T3"] = -1439679693] = "ANTHEM_ELEVATE_T3";
    HeaderHash[HeaderHash["ANTHEM_ELEVATE_T4"] = 809632595] = "ANTHEM_ELEVATE_T4";
    HeaderHash[HeaderHash["CIGNA"] = -1749409865] = "CIGNA";
    HeaderHash[HeaderHash["CIGNA_HEALTH"] = 345462485] = "CIGNA_HEALTH";
    HeaderHash[HeaderHash["COLONIAL_V1"] = 1406913136] = "COLONIAL_V1";
    HeaderHash[HeaderHash["COLONIAL_V2"] = 2102543717] = "COLONIAL_V2";
    HeaderHash[HeaderHash["AENTA"] = 1338015378] = "AENTA";
    HeaderHash[HeaderHash["CHOICE_BUILDER"] = -1107677135] = "CHOICE_BUILDER";
    HeaderHash[HeaderHash["TRANSAMERICA_T1"] = 700461480] = "TRANSAMERICA_T1";
    HeaderHash[HeaderHash["TRANSAMERICA_T2"] = -1856448197] = "TRANSAMERICA_T2";
    HeaderHash[HeaderHash["NETWORK_HEALTH_T1"] = 63486933] = "NETWORK_HEALTH_T1";
    HeaderHash[HeaderHash["NETWORK_HEALTH_T2"] = -1847391623] = "NETWORK_HEALTH_T2";
    HeaderHash[HeaderHash["NETWORK_HEALTH_T3"] = 1690149631] = "NETWORK_HEALTH_T3";
    HeaderHash[HeaderHash["ASSURITY_T1"] = 1219353088] = "ASSURITY_T1";
    HeaderHash[HeaderHash["ASSURITY_T2"] = -460627580] = "ASSURITY_T2";
    HeaderHash[HeaderHash["BERKSHIRE_HATHAWAY"] = -1028666888] = "BERKSHIRE_HATHAWAY";
    HeaderHash[HeaderHash["PROGRESSIVE_T1"] = 1774066782] = "PROGRESSIVE_T1";
    HeaderHash[HeaderHash["PROGRESSIVE_T2"] = -600706983] = "PROGRESSIVE_T2";
    HeaderHash[HeaderHash["PROGRESSIVE_T3"] = 456298464] = "PROGRESSIVE_T3";
    HeaderHash[HeaderHash["BLUE_CROSS"] = 91491873] = "BLUE_CROSS";
    HeaderHash[HeaderHash["ALLIED_ADMINISTRATORS"] = -1863186151] = "ALLIED_ADMINISTRATORS";
    HeaderHash[HeaderHash["LIFE_MAP"] = -214646849] = "LIFE_MAP";
    HeaderHash[HeaderHash["SELMAN"] = 294085845] = "SELMAN";
    HeaderHash[HeaderHash["PREMERA"] = 908996119] = "PREMERA";
    HeaderHash[HeaderHash["RELIANCE_STANDARD_T1"] = -863244252] = "RELIANCE_STANDARD_T1";
    HeaderHash[HeaderHash["RELIANCE_STANDARD_T2"] = -501836868] = "RELIANCE_STANDARD_T2";
    HeaderHash[HeaderHash["GUIDE_ONE_T1"] = -1304787503] = "GUIDE_ONE_T1";
    HeaderHash[HeaderHash["GUIDE_ONE_T2"] = -950525751] = "GUIDE_ONE_T2";
    HeaderHash[HeaderHash["MORGAN_WHITE_T1"] = 907870896] = "MORGAN_WHITE_T1";
    HeaderHash[HeaderHash["MORGAN_WHITE_T2"] = -1879860853] = "MORGAN_WHITE_T2";
    HeaderHash[HeaderHash["LINCOLN_V1"] = 463130703] = "LINCOLN_V1";
    HeaderHash[HeaderHash["LINCOLN_V2"] = -520984725] = "LINCOLN_V2";
    HeaderHash[HeaderHash["LINCOLN_WEB"] = -1098852486] = "LINCOLN_WEB";
    HeaderHash[HeaderHash["LIFEMAP_T1"] = -214646849] = "LIFEMAP_T1";
    HeaderHash[HeaderHash["GUIDEONE_T1"] = 635271396] = "GUIDEONE_T1";
    HeaderHash[HeaderHash["GUIDEONE_T2"] = 152091190] = "GUIDEONE_T2";
    HeaderHash[HeaderHash["GUIDEONE_T3"] = -1304787503] = "GUIDEONE_T3";
    HeaderHash[HeaderHash["UMR_CIK"] = -867552983] = "UMR_CIK";
    HeaderHash[HeaderHash["US_ASSURE"] = -1715666750] = "US_ASSURE";
    HeaderHash[HeaderHash["SCOTT_WHITE"] = -455852343] = "SCOTT_WHITE";
    HeaderHash[HeaderHash["LIFELOCK"] = 771425499] = "LIFELOCK";
    HeaderHash[HeaderHash["METLIFE_LEGAL_PLANS"] = 2088989137] = "METLIFE_LEGAL_PLANS";
    HeaderHash[HeaderHash["BEAM"] = -784914619] = "BEAM";
    HeaderHash[HeaderHash["AIG"] = 960041760] = "AIG";
    HeaderHash[HeaderHash["AIG_T1"] = -465712243] = "AIG_T1";
    HeaderHash[HeaderHash["AIG_T2"] = 259427844] = "AIG_T2";
    HeaderHash[HeaderHash["JOHN_HANCOCK"] = -988192416] = "JOHN_HANCOCK";
    HeaderHash[HeaderHash["KELLY_BENEFITS_T1"] = 788572499] = "KELLY_BENEFITS_T1";
    HeaderHash[HeaderHash["KELLY_BENEFITS_T2"] = -1504409257] = "KELLY_BENEFITS_T2";
    HeaderHash[HeaderHash["KELLY_BENEFITS_T3"] = 544836928] = "KELLY_BENEFITS_T3";
    HeaderHash[HeaderHash["ELEVANCE_HEALTH_T1"] = -1511257889] = "ELEVANCE_HEALTH_T1";
    HeaderHash[HeaderHash["ELEVANCE_HEALTH_T2"] = 521473428] = "ELEVANCE_HEALTH_T2";
    HeaderHash[HeaderHash["RT_SPECIALTY"] = 751586507] = "RT_SPECIALTY";
    HeaderHash[HeaderHash["MUTUAL_OF_OMAHA"] = -1204123841] = "MUTUAL_OF_OMAHA";
    HeaderHash[HeaderHash["MUTUAL_OF_OMAHA_T1"] = -1359975791] = "MUTUAL_OF_OMAHA_T1";
    HeaderHash[HeaderHash["MUTUAL_OF_OMAHA_T2"] = -888161719] = "MUTUAL_OF_OMAHA_T2";
    HeaderHash[HeaderHash["SOUTHERN_FIDELITY_HOLDINGS"] = -540862744] = "SOUTHERN_FIDELITY_HOLDINGS";
    HeaderHash[HeaderHash["SOUTHERN_TRUST_T1"] = -2003856622] = "SOUTHERN_TRUST_T1";
    HeaderHash[HeaderHash["SOUTHERN_TRUST_T2"] = 2119974850] = "SOUTHERN_TRUST_T2";
    HeaderHash[HeaderHash["COMP_WEST"] = 1357032630] = "COMP_WEST";
    HeaderHash[HeaderHash["FCCI_INSURANCE_GROUP"] = 118602505] = "FCCI_INSURANCE_GROUP";
    HeaderHash[HeaderHash["CITIZENS"] = 1479201104] = "CITIZENS";
    HeaderHash[HeaderHash["SUMMIT"] = 1673316219] = "SUMMIT";
    HeaderHash[HeaderHash["NEW_YORK_LIFE"] = -1376226013] = "NEW_YORK_LIFE";
    HeaderHash[HeaderHash["UMR"] = 1336716457] = "UMR";
    HeaderHash[HeaderHash["ONE_AMERICA"] = -1892516032] = "ONE_AMERICA";
    HeaderHash[HeaderHash["FNA"] = -1048159107] = "FNA";
    HeaderHash[HeaderHash["COMBINED"] = -1238271615] = "COMBINED";
    HeaderHash[HeaderHash["LIBERTY_DENTAL"] = -976189515] = "LIBERTY_DENTAL";
    HeaderHash[HeaderHash["PHILADELPHIA"] = 84085496] = "PHILADELPHIA";
    HeaderHash[HeaderHash["MUTUAL_HEALTH"] = -267460134] = "MUTUAL_HEALTH";
    HeaderHash[HeaderHash["CNA_T1"] = 2133964610] = "CNA_T1";
    HeaderHash[HeaderHash["CNA_T2"] = 2136738352] = "CNA_T2";
    HeaderHash[HeaderHash["CNA_T3"] = 426266025] = "CNA_T3";
    HeaderHash[HeaderHash["CNA_T4"] = 2037664013] = "CNA_T4";
    HeaderHash[HeaderHash["CNA_T5"] = -856587690] = "CNA_T5";
    HeaderHash[HeaderHash["CNA_T6"] = 897350831] = "CNA_T6";
    HeaderHash[HeaderHash["CNA_T7"] = 1665642846] = "CNA_T7";
    HeaderHash[HeaderHash["CNA_T8"] = -187480222] = "CNA_T8";
    HeaderHash[HeaderHash["SAIF"] = -2068718830] = "SAIF";
    HeaderHash[HeaderHash["HOMEOWNERS_OF_AMERICA"] = 1372202132] = "HOMEOWNERS_OF_AMERICA";
    HeaderHash[HeaderHash["KAISER_T1"] = 231982573] = "KAISER_T1";
    HeaderHash[HeaderHash["KAISER_T2"] = 356854618] = "KAISER_T2";
    HeaderHash[HeaderHash["BENEFIT_DIRECT"] = -427974225] = "BENEFIT_DIRECT";
    HeaderHash[HeaderHash["SWANSON_PHILLIPS"] = 1864889255] = "SWANSON_PHILLIPS";
    HeaderHash[HeaderHash["PRO_WRITERS"] = 1699904055] = "PRO_WRITERS";
    HeaderHash[HeaderHash["HARTFORD_BENEFITS"] = -1859707036] = "HARTFORD_BENEFITS";
    HeaderHash[HeaderHash["GEOVERA"] = 1205147386] = "GEOVERA";
    HeaderHash[HeaderHash["FARMERS"] = -686725843] = "FARMERS";
    HeaderHash[HeaderHash["LANDMARK"] = 1007575143] = "LANDMARK";
    HeaderHash[HeaderHash["STEALTH_PARTNERS_V1"] = 253038435] = "STEALTH_PARTNERS_V1";
    HeaderHash[HeaderHash["STEALTH_PARTNERS_V2"] = 620363084] = "STEALTH_PARTNERS_V2";
    HeaderHash[HeaderHash["AUTO_OWNERS_V1"] = 734091838] = "AUTO_OWNERS_V1";
    HeaderHash[HeaderHash["AUTO_OWNERS_V2_T1"] = -998124801] = "AUTO_OWNERS_V2_T1";
    HeaderHash[HeaderHash["AUTO_OWNERS_V2_T2"] = -1620258314] = "AUTO_OWNERS_V2_T2";
    HeaderHash[HeaderHash["MEDICAL_MUTUAL"] = 161736768] = "MEDICAL_MUTUAL";
    HeaderHash[HeaderHash["MEDICAL_MUTUAL_T1"] = -391316847] = "MEDICAL_MUTUAL_T1";
    HeaderHash[HeaderHash["HORIZONS_HEALTHCARE_SERVICES"] = 86788005] = "HORIZONS_HEALTHCARE_SERVICES";
    HeaderHash[HeaderHash["KAISER_GA"] = 1840737117] = "KAISER_GA";
    HeaderHash[HeaderHash["BEACON_MUTUAL_T1"] = -1223648528] = "BEACON_MUTUAL_T1";
    HeaderHash[HeaderHash["BEACON_MUTUAL_T2"] = 427608045] = "BEACON_MUTUAL_T2";
    HeaderHash[HeaderHash["WESTERN_RESERVE"] = -187128799] = "WESTERN_RESERVE";
    HeaderHash[HeaderHash["ALLSTATE_T1"] = 365852916] = "ALLSTATE_T1";
    HeaderHash[HeaderHash["ALLSTATE_T2"] = 89478696] = "ALLSTATE_T2";
    HeaderHash[HeaderHash["ABACUS"] = 1071139163] = "ABACUS";
    HeaderHash[HeaderHash["KANDK"] = 641383301] = "KANDK";
    HeaderHash[HeaderHash["BUILDERS"] = 20490216] = "BUILDERS";
    HeaderHash[HeaderHash["DELTA_DENTAL_NJ"] = 1400461214] = "DELTA_DENTAL_NJ";
    HeaderHash[HeaderHash["HANOVER_T1"] = 847442772] = "HANOVER_T1";
    HeaderHash[HeaderHash["HANOVER_T2"] = -2048118885] = "HANOVER_T2";
    HeaderHash[HeaderHash["SUMMACARE"] = -1734760577] = "SUMMACARE";
    HeaderHash[HeaderHash["ASSURANT"] = -654029279] = "ASSURANT";
    HeaderHash[HeaderHash["BROWN_AND_RIDING"] = 2064510634] = "BROWN_AND_RIDING";
    HeaderHash[HeaderHash["DEMO_BCBS"] = 464039538] = "DEMO_BCBS";
    HeaderHash[HeaderHash["CA_FAIR_PLAN"] = 1108857435] = "CA_FAIR_PLAN";
    HeaderHash[HeaderHash["COMBINED_GROUP"] = -637918263] = "COMBINED_GROUP";
    HeaderHash[HeaderHash["INDEPENDENT_INS_AGENTS"] = 534089789] = "INDEPENDENT_INS_AGENTS";
    HeaderHash[HeaderHash["SOCIAL_SERVICES_CONTRACTOR"] = 1270161238] = "SOCIAL_SERVICES_CONTRACTOR";
    HeaderHash[HeaderHash["AMERICAN_PUBLIC_LIFE"] = -1024025045] = "AMERICAN_PUBLIC_LIFE";
    HeaderHash[HeaderHash["THE_HILB_GROUP"] = -590328759] = "THE_HILB_GROUP";
    HeaderHash[HeaderHash["AVESIS"] = -1258314369] = "AVESIS";
    HeaderHash[HeaderHash["PIE_INSURANCE_SERVICES"] = -1371470898] = "PIE_INSURANCE_SERVICES";
    HeaderHash[HeaderHash["G_AND_G_UNDERWRITERS"] = -1902142522] = "G_AND_G_UNDERWRITERS";
    HeaderHash[HeaderHash["UHC"] = 378730522] = "UHC";
    HeaderHash[HeaderHash["UHC_T1"] = -1614235138] = "UHC_T1";
    HeaderHash[HeaderHash["BOSTON_INSURANCE_BROKERAGE"] = 691190137] = "BOSTON_INSURANCE_BROKERAGE";
    HeaderHash[HeaderHash["GREAT_AMERICAN_INSURANCE_GROUP"] = -1735445823] = "GREAT_AMERICAN_INSURANCE_GROUP";
    HeaderHash[HeaderHash["SENTINEL"] = -1821413563] = "SENTINEL";
    HeaderHash[HeaderHash["RLI_INSURANCE_COMPANY"] = -1109319530] = "RLI_INSURANCE_COMPANY";
    // Either Vigilante Group, Omnitrade, Western Health Insurance Trust,
    // Business Health Trust, Washington Technology, etc.
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T1"] = -2107752555] = "ARMFIELD_HARRISON_THOMAS_T1";
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T2"] = -777881510] = "ARMFIELD_HARRISON_THOMAS_T2";
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T3"] = 2122544151] = "ARMFIELD_HARRISON_THOMAS_T3";
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T4"] = -416705071] = "ARMFIELD_HARRISON_THOMAS_T4";
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T5"] = -1543237797] = "ARMFIELD_HARRISON_THOMAS_T5";
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T6"] = -589783015] = "ARMFIELD_HARRISON_THOMAS_T6";
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T7"] = -1392546660] = "ARMFIELD_HARRISON_THOMAS_T7";
    HeaderHash[HeaderHash["ARMFIELD_HARRISON_THOMAS_T8"] = -1357109946] = "ARMFIELD_HARRISON_THOMAS_T8";
    /** Carrier Payable Hashes */
    HeaderHash[HeaderHash["BTIS_T1"] = -334172288] = "BTIS_T1";
    HeaderHash[HeaderHash["BTIS_T2"] = -461347204] = "BTIS_T2";
    HeaderHash[HeaderHash["CHRISTIAN_BROTHERS"] = 1633188219] = "CHRISTIAN_BROTHERS";
    HeaderHash[HeaderHash["CRC_T1"] = -1853220630] = "CRC_T1";
    HeaderHash[HeaderHash["CRC_T2"] = -1035360138] = "CRC_T2";
    HeaderHash[HeaderHash["GREAT_AMERICAN"] = 511893034] = "GREAT_AMERICAN";
    HeaderHash[HeaderHash["LIBERTY_MUTUAL_T1"] = 1668689921] = "LIBERTY_MUTUAL_T1";
    HeaderHash[HeaderHash["LIBERTY_MUTUAL_T2"] = -594286938] = "LIBERTY_MUTUAL_T2";
    HeaderHash[HeaderHash["MONARCH"] = 1013727245] = "MONARCH";
    HeaderHash[HeaderHash["XS_BROKERS"] = -1681067206] = "XS_BROKERS";
    HeaderHash[HeaderHash["YORK_INTERNATIONAL"] = 34869826] = "YORK_INTERNATIONAL";
    /** EXCEL-derived hashes */
    HeaderHash[HeaderHash["EXCEL_DELTA_DENTAL"] = 490717673] = "EXCEL_DELTA_DENTAL";
    HeaderHash[HeaderHash["EXCEL_MVP_HEALTH_CARE"] = -751368908] = "EXCEL_MVP_HEALTH_CARE";
    HeaderHash[HeaderHash["EXCEL_PHILADELPHIA_T1"] = 465916505] = "EXCEL_PHILADELPHIA_T1";
    HeaderHash[HeaderHash["EXCEL_PHILADELPHIA_T2"] = -259460370] = "EXCEL_PHILADELPHIA_T2";
    HeaderHash[HeaderHash["EXCEL_DELTA_DENTAL_ARKANSAS"] = -581995413] = "EXCEL_DELTA_DENTAL_ARKANSAS";
    HeaderHash[HeaderHash["EXCEL_HEALTHEZ"] = -1317451286] = "EXCEL_HEALTHEZ";
    HeaderHash[HeaderHash["EXCEL_LIFE_LOCK"] = 1098858215] = "EXCEL_LIFE_LOCK";
    HeaderHash[HeaderHash["EXCEL_BANKERS_FIDELITY"] = 274125415] = "EXCEL_BANKERS_FIDELITY";
    HeaderHash[HeaderHash["EXCEL_BENEFIT_ALLIANCE"] = -1584996959] = "EXCEL_BENEFIT_ALLIANCE";
    HeaderHash[HeaderHash["EXCEL_PEHP"] = 1529984166] = "EXCEL_PEHP";
    HeaderHash[HeaderHash["EXCEL_PREBEN"] = -1899092979] = "EXCEL_PREBEN";
    HeaderHash[HeaderHash["EXCEL_PEQUOT_PLUS"] = 1147278088] = "EXCEL_PEQUOT_PLUS";
    HeaderHash[HeaderHash["EXCEL_SHELTERPOINT"] = -1125179252] = "EXCEL_SHELTERPOINT";
    HeaderHash[HeaderHash["EXCEL_PPI"] = -530398165] = "EXCEL_PPI";
    // NOTE: Both AMWINS formats are different
    HeaderHash[HeaderHash["EXCEL_AMWINS_V1"] = -854190007] = "EXCEL_AMWINS_V1";
    HeaderHash[HeaderHash["EXCEL_AMWINS_V2"] = 1010119085] = "EXCEL_AMWINS_V2";
    HeaderHash[HeaderHash["EXCEL_ANCHOR"] = 1201494523] = "EXCEL_ANCHOR";
    HeaderHash[HeaderHash["EXCEL_HM_LIFE"] = 1015476693] = "EXCEL_HM_LIFE";
    HeaderHash[HeaderHash["EXCEL_ALLEGIANCE"] = 1132822607] = "EXCEL_ALLEGIANCE";
    HeaderHash[HeaderHash["EXCEL_ADP_T1"] = 388423074] = "EXCEL_ADP_T1";
    HeaderHash[HeaderHash["EXCEL_ADP_T2"] = 1400422138] = "EXCEL_ADP_T2";
    HeaderHash[HeaderHash["EXCEL_VSP"] = -373886838] = "EXCEL_VSP";
    HeaderHash[HeaderHash["EXCEL_FHM"] = 263353213] = "EXCEL_FHM";
    HeaderHash[HeaderHash["EXCEL_ANTHEM_BLUE_CROSS"] = -27407007] = "EXCEL_ANTHEM_BLUE_CROSS";
    HeaderHash[HeaderHash["EXCEL_FFVA"] = 1551501143] = "EXCEL_FFVA";
    HeaderHash[HeaderHash["EXCEL_ANCHOR_GROUP"] = 1701875059] = "EXCEL_ANCHOR_GROUP";
    HeaderHash[HeaderHash["EXCEL_MERCURY"] = -1711117236] = "EXCEL_MERCURY";
    HeaderHash[HeaderHash["EXCEL_MEDICAL_MUTUAL_OF_OHIO"] = -1801751623] = "EXCEL_MEDICAL_MUTUAL_OF_OHIO";
    HeaderHash[HeaderHash["EXCEL_LINCOLN"] = -1539557561] = "EXCEL_LINCOLN";
    HeaderHash[HeaderHash["EXCEL_TOKIO_MARINE_T1"] = 590117226] = "EXCEL_TOKIO_MARINE_T1";
    HeaderHash[HeaderHash["EXCEL_TOKIO_MARINE_T2"] = -237629214] = "EXCEL_TOKIO_MARINE_T2";
    HeaderHash[HeaderHash["EXCEL_STAR_INSURANCE_COMPANY"] = 843429261] = "EXCEL_STAR_INSURANCE_COMPANY";
    HeaderHash[HeaderHash["EXCEL_JP_FARLEY"] = 694831927] = "EXCEL_JP_FARLEY";
    HeaderHash[HeaderHash["EXCEL_CASON"] = 614940687] = "EXCEL_CASON";
    HeaderHash[HeaderHash["EXCEL_LANDMARK"] = 1311145357] = "EXCEL_LANDMARK";
    HeaderHash[HeaderHash["EXCEL_CIGNA"] = 247319255] = "EXCEL_CIGNA";
    HeaderHash[HeaderHash["EXCEL_HOWELL_BENEFITS"] = 2057254297] = "EXCEL_HOWELL_BENEFITS";
    HeaderHash[HeaderHash["EXCEL_NATIONWIDE"] = 548658682] = "EXCEL_NATIONWIDE";
    HeaderHash[HeaderHash["EXCEL_BLUCR_MA"] = -80597335] = "EXCEL_BLUCR_MA";
    HeaderHash[HeaderHash["EXCEL_WPS"] = -1206849287] = "EXCEL_WPS";
    HeaderHash[HeaderHash["EXCEL_DELTA_DENTAL_WI"] = -865523796] = "EXCEL_DELTA_DENTAL_WI";
    HeaderHash[HeaderHash["EXCEL_NATIONWIDE_AGRIBUSINESS"] = 733700336] = "EXCEL_NATIONWIDE_AGRIBUSINESS";
    HeaderHash[HeaderHash["EXCEL_JUSTWORKS"] = -745427236] = "EXCEL_JUSTWORKS";
    HeaderHash[HeaderHash["EXCEL_VAULT_ADMIN_SERVICES"] = -140352234] = "EXCEL_VAULT_ADMIN_SERVICES";
    HeaderHash[HeaderHash["EXCEL_MILLENIAL_SPECIALTY_INSURANCE"] = -2011552457] = "EXCEL_MILLENIAL_SPECIALTY_INSURANCE";
    HeaderHash[HeaderHash["EXCEL_BCBS_ARIZONA"] = -2055044442] = "EXCEL_BCBS_ARIZONA";
    HeaderHash[HeaderHash["EXCEL_HEALTHCARE_MANAGEMENT_ADMINISTRATORS"] = -1178796181] = "EXCEL_HEALTHCARE_MANAGEMENT_ADMINISTRATORS";
    HeaderHash[HeaderHash["EXCEL_AETNA"] = 9807077] = "EXCEL_AETNA";
    HeaderHash[HeaderHash["EXCEL_HUMANA"] = -693303395] = "EXCEL_HUMANA";
    HeaderHash[HeaderHash["EXCEL_ADP"] = -1461754992] = "EXCEL_ADP";
    HeaderHash[HeaderHash["EXCEL_UHC"] = -1179034133] = "EXCEL_UHC";
    HeaderHash[HeaderHash["EXCEL_THE_HEALTH_PLAN"] = -554982923] = "EXCEL_THE_HEALTH_PLAN";
    HeaderHash[HeaderHash["EXCEL_ALLEGIANCE_BENEFIT_PLAN_MANAGEMENT"] = 1584233391] = "EXCEL_ALLEGIANCE_BENEFIT_PLAN_MANAGEMENT";
    HeaderHash[HeaderHash["EXCEL_S3_DIRECT_INSURANCE_SERVICES"] = -356035843] = "EXCEL_S3_DIRECT_INSURANCE_SERVICES";
    HeaderHash[HeaderHash["EXCEL_DEAN_HEALTH_PLAN"] = 881082592] = "EXCEL_DEAN_HEALTH_PLAN";
    HeaderHash[HeaderHash["EXCEL_SBLI"] = 1620034595] = "EXCEL_SBLI";
    HeaderHash[HeaderHash["EXCEL_FNA_INSURANCE_SERVICES"] = -1336244969] = "EXCEL_FNA_INSURANCE_SERVICES";
    HeaderHash[HeaderHash["EXCEL_TRINET_GROUP"] = -1990063354] = "EXCEL_TRINET_GROUP";
})(HeaderHash || (exports.HeaderHash = HeaderHash = {}));
var Organization;
(function (Organization) {
    Organization["BRP"] = "BRP";
    Organization["HIGHSTREET"] = "HIGHSTREET";
    Organization["TOMPKINS"] = "TOMPKINS";
})(Organization || (exports.Organization = Organization = {}));
exports.ORG_ID_DICT = {
    [Organization.BRP]: "66181f5a-9ec4-400a-8366-e96c3c28d260",
    [Organization.HIGHSTREET]: "7bde521b-e9ee-47ab-a2f5-7e14b66a87c2",
    [Organization.TOMPKINS]: "483627c3-f310-407e-bd9b-a4f7e8e1e999",
};
exports.SHOULD_CONVERT_XLS_TO_XLSX = [
    HeaderHash.EXCEL_HUMANA.valueOf(),
];
exports.SHOULD_CONVERT_XLXS_TO_PDF = [
    HeaderHash.EXCEL_DELTA_DENTAL.valueOf(),
    HeaderHash.EXCEL_MVP_HEALTH_CARE.valueOf(),
    HeaderHash.EXCEL_PHILADELPHIA_T1.valueOf(),
    HeaderHash.EXCEL_PHILADELPHIA_T2.valueOf(),
    HeaderHash.EXCEL_DELTA_DENTAL_ARKANSAS.valueOf(),
    HeaderHash.EXCEL_HEALTHEZ.valueOf(),
    HeaderHash.EXCEL_LIFE_LOCK.valueOf(),
    HeaderHash.EXCEL_BANKERS_FIDELITY.valueOf(),
    HeaderHash.EXCEL_BENEFIT_ALLIANCE.valueOf(),
    HeaderHash.EXCEL_PEHP.valueOf(),
    HeaderHash.EXCEL_PEQUOT_PLUS.valueOf(),
    HeaderHash.EXCEL_SHELTERPOINT.valueOf(),
    HeaderHash.EXCEL_PPI.valueOf(),
    HeaderHash.EXCEL_AMWINS_V1.valueOf(),
    HeaderHash.EXCEL_ANCHOR.valueOf(),
    HeaderHash.EXCEL_HM_LIFE.valueOf(),
    HeaderHash.EXCEL_ALLEGIANCE.valueOf(),
    HeaderHash.EXCEL_ADP_T1.valueOf(),
    HeaderHash.EXCEL_ADP_T2.valueOf(),
    HeaderHash.EXCEL_ANCHOR_GROUP.valueOf(),
    HeaderHash.EXCEL_MEDICAL_MUTUAL_OF_OHIO.valueOf(),
    HeaderHash.EXCEL_TOKIO_MARINE_T1.valueOf(),
    HeaderHash.EXCEL_TOKIO_MARINE_T2.valueOf(),
    HeaderHash.EXCEL_STAR_INSURANCE_COMPANY.valueOf(),
    HeaderHash.EXCEL_LANDMARK.valueOf(),
    HeaderHash.EXCEL_CIGNA.valueOf(),
    HeaderHash.EXCEL_HOWELL_BENEFITS.valueOf(),
    HeaderHash.EXCEL_NATIONWIDE_AGRIBUSINESS.valueOf(),
    HeaderHash.EXCEL_JUSTWORKS.valueOf(),
    HeaderHash.EXCEL_VAULT_ADMIN_SERVICES.valueOf(),
    HeaderHash.EXCEL_MILLENIAL_SPECIALTY_INSURANCE.valueOf(),
    HeaderHash.EXCEL_BCBS_ARIZONA.valueOf(),
    HeaderHash.EXCEL_HEALTHCARE_MANAGEMENT_ADMINISTRATORS.valueOf(),
    HeaderHash.EXCEL_ADP.valueOf(),
    HeaderHash.EXCEL_THE_HEALTH_PLAN.valueOf(),
    HeaderHash.EXCEL_ALLEGIANCE_BENEFIT_PLAN_MANAGEMENT.valueOf(),
    HeaderHash.EXCEL_S3_DIRECT_INSURANCE_SERVICES.valueOf(),
    HeaderHash.EXCEL_FNA_INSURANCE_SERVICES.valueOf(),
    HeaderHash.EXCEL_TRINET_GROUP.valueOf(),
];
exports.SHOULD_NOT_CONVERT_XLXS_TO_PDF = [
    HeaderHash.EXCEL_JP_FARLEY.valueOf(),
    HeaderHash.EXCEL_NATIONWIDE.valueOf(),
    HeaderHash.EXCEL_AMWINS_V2.valueOf(),
];
exports.XLXS_TO_PDF_CONFIG_OPTIONS = {
    [HeaderHash.EXCEL_LIFE_LOCK]: {
        Scale: 45,
    },
    [HeaderHash.EXCEL_PHILADELPHIA_T1]: {
        PageSize: "Tabloid",
    },
    [HeaderHash.EXCEL_PHILADELPHIA_T2]: {
        PageSize: "Tabloid",
    },
    [HeaderHash.EXCEL_AMWINS_V1]: {
        PageSize: "Tabloid",
    },
    [HeaderHash.EXCEL_ADP]: {
        PageSize: "Tabloid",
    },
    [HeaderHash.EXCEL_SHELTERPOINT]: {
        Scale: 50,
    },
    [HeaderHash.EXCEL_ADP_T1]: {
        Scale: 50,
    },
    [HeaderHash.EXCEL_ADP_T2]: {
        Scale: 50,
    },
};
exports.RASTERIZE_PDF_CONFIG_OPTIONS = {
    [HeaderHash.AUTO_OWNERS_V1]: {
        Resolution: 150,
    },
};
exports.USE_AZURE_AS_PRIMARY_SOURCE_HEADER_HASHES = [
    HeaderHash.HARTFORD_T1.valueOf(),
    HeaderHash.HARTFORD_T2.valueOf(),
    HeaderHash.HARTFORD_T3.valueOf(),
    HeaderHash.HARTFORD_T4.valueOf(),
    HeaderHash.HARTFORD_T5.valueOf(),
    HeaderHash.HARTFORD_T7.valueOf(),
    HeaderHash.HARTFORD_T8.valueOf(),
    HeaderHash.HARTFORD_T9.valueOf(),
    HeaderHash.HARTFORD_T10.valueOf(),
    HeaderHash.HARTFORD_T11.valueOf(),
    HeaderHash.AMERICAN_BANKERS.valueOf(),
    HeaderHash.AUTO_OWNERS_V2_T2.valueOf(),
    HeaderHash.HUMANA_T1.valueOf(),
    HeaderHash.HUMANA_T2.valueOf(),
    HeaderHash.HUMANA_T3.valueOf(),
    HeaderHash.HUMANA_T4.valueOf(),
    HeaderHash.HUMANA_T5.valueOf(),
    HeaderHash.EYE_MED.valueOf(),
    HeaderHash.ACUITY.valueOf(),
    HeaderHash.GEOVERA_T1.valueOf(),
    HeaderHash.GEOVERA_T2.valueOf(),
    HeaderHash.GUIDE_ONE_T1.valueOf(),
    HeaderHash.GUIDE_ONE_T2.valueOf(),
    HeaderHash.RELIANCE_STANDARD_T2.valueOf(),
    HeaderHash.LINCOLN_V1.valueOf(),
    HeaderHash.BCBS_NE.valueOf(),
    HeaderHash.LIFEMAP_T1.valueOf(),
    HeaderHash.GUIDEONE_T1.valueOf(),
    HeaderHash.GUIDEONE_T2.valueOf(),
    HeaderHash.GUIDEONE_T3.valueOf(),
    HeaderHash.BCBS_TX.valueOf(),
    HeaderHash.US_ASSURE.valueOf(),
    HeaderHash.LIFELOCK.valueOf(),
    HeaderHash.AIG.valueOf(),
    HeaderHash.BEAM.valueOf(),
    HeaderHash.JOHN_HANCOCK.valueOf(),
    HeaderHash.AIG_T1.valueOf(),
    HeaderHash.AIG_T2.valueOf(),
    HeaderHash.MUTUAL_OF_OMAHA_T1.valueOf(),
    HeaderHash.SOUTHERN_FIDELITY_HOLDINGS.valueOf(),
    HeaderHash.SOUTHERN_TRUST_T1.valueOf(),
    HeaderHash.SOUTHERN_TRUST_T2.valueOf(),
    HeaderHash.FCCI_INSURANCE_GROUP.valueOf(),
    HeaderHash.SUMMIT.valueOf(),
    HeaderHash.METLIFE_T2.valueOf(),
    HeaderHash.HARTFORD_BENEFITS.valueOf(),
    HeaderHash.GEOVERA.valueOf(),
    HeaderHash.MEDICAL_MUTUAL_T1.valueOf(),
    HeaderHash.HORIZONS_HEALTHCARE_SERVICES.valueOf(),
    HeaderHash.ALLSTATE_T1.valueOf(),
    HeaderHash.ALLSTATE_T2.valueOf(),
    HeaderHash.ABACUS.valueOf(),
    HeaderHash.PRINCIPAL.valueOf(),
    HeaderHash.KANDK.valueOf(),
    HeaderHash.MUTUAL_OF_OMAHA_T2.valueOf(),
    HeaderHash.SUMMACARE.valueOf(),
    HeaderHash.ASSURANT.valueOf(),
    HeaderHash.DEMO_BCBS.valueOf(),
    HeaderHash.NETWORK_HEALTH_T2.valueOf(),
    HeaderHash.NETWORK_HEALTH_T3.valueOf(),
    HeaderHash.SOCIAL_SERVICES_CONTRACTOR.valueOf(),
    HeaderHash.CIGNA.valueOf(),
    HeaderHash.AMERICAN_PUBLIC_LIFE.valueOf(),
    HeaderHash.THE_HILB_GROUP.valueOf(),
    HeaderHash.AVESIS.valueOf(),
    HeaderHash.PIE_INSURANCE_SERVICES.valueOf(),
    HeaderHash.G_AND_G_UNDERWRITERS.valueOf(),
    HeaderHash.UHC.valueOf(),
    HeaderHash.UHC_T1.valueOf(),
    HeaderHash.BOSTON_INSURANCE_BROKERAGE.valueOf(),
    HeaderHash.GREAT_AMERICAN_INSURANCE_GROUP.valueOf(),
    HeaderHash.RLI_INSURANCE_COMPANY.valueOf(),
];
exports.RASTERIZE_HEADER_HASHES = [
    HeaderHash.HARTFORD_T1.valueOf(),
    HeaderHash.HARTFORD_T2.valueOf(),
    HeaderHash.HARTFORD_T3.valueOf(),
    HeaderHash.HARTFORD_T4.valueOf(),
    HeaderHash.HARTFORD_T5.valueOf(),
    HeaderHash.HARTFORD_T7.valueOf(),
    HeaderHash.HARTFORD_T8.valueOf(),
    HeaderHash.HARTFORD_T9.valueOf(),
    HeaderHash.HARTFORD_T10.valueOf(),
    HeaderHash.HARTFORD_T11.valueOf(),
    HeaderHash.BCBS_NE.valueOf(),
    HeaderHash.BCBS_TX.valueOf(),
    HeaderHash.CIGNA_HEALTH.valueOf(),
    HeaderHash.HEALTH_NET_LIFE.valueOf(),
    HeaderHash.AUTO_OWNERS_V1.valueOf(),
];
exports.SHOULD_SPLICE_IN_AZURE_HEADER_HASHES = [
    HeaderHash.CIGNA.valueOf(),
    HeaderHash.NETWORK_HEALTH_T1.valueOf(),
    HeaderHash.SCOTT_WHITE.valueOf(),
    HeaderHash.METLIFE_LEGAL_PLANS.valueOf(),
    HeaderHash.AUTO_OWNERS_V2_T1.valueOf(),
    HeaderHash.HARTFORD_T6.valueOf(),
    HeaderHash.MUTUAL_OF_OMAHA.valueOf(),
    HeaderHash.RELIANCE_STANDARD_T1.valueOf(),
];
exports.EXEMPT_FORCE_ADEM_HEADER_HASHES = [
    HeaderHash.PROGRESSIVE_T1,
    HeaderHash.PROGRESSIVE_T2,
    HeaderHash.BLUE_CROSS,
    HeaderHash.ELEVANCE_HEALTH_T1,
    HeaderHash.ELEVANCE_HEALTH_T2,
    HeaderHash.RT_SPECIALTY,
    HeaderHash.LIBERTY_DENTAL,
    HeaderHash.BEAM,
];
exports.FORCE_DETECT_SKEW_HEADER_HASHES = [
    HeaderHash.METLIFE_T1.valueOf(),
    HeaderHash.METLIFE_T2.valueOf(),
];
exports.ENABLE_PARTIAL_DATE_TYPE_COLUMN_MAPPING_HEADER_HASHES = [
    HeaderHash.EXCEL_FHM,
    HeaderHash.EXCEL_ANTHEM_BLUE_CROSS,
    HeaderHash.EXCEL_FFVA,
];
exports.COMBINED_TABLE_CONFIGS = {
    [HeaderHash.EXCEL_MERCURY]: {
        sourceSheetName: "Adjustment Details",
        sourceTableHeaders: [
            "INSURED NAME",
            "TRANSACTION DATE",
            "POLICY",
            "COMPANY",
            "PRODUCT LINE",
            "UNCOLLECTED EARNED PREMIUM",
            "RATE",
            "COMMISSION CHARGEBACK",
            "POLICY TYPE",
            "AGENCY CODE",
            "AGENCY NAME",
        ],
        targetSheetName: "Commission Statement",
    },
};
