"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTemplates = void 0;
const _ = __importStar(require("lodash"));
const rows_1 = require("../autofill/rows");
const graphql_types_1 = require("@comulate/graphql-types");
const utils_1 = require("../../common/utils");
const constants_1 = require("../constants");
const constants_2 = require("../autofill/constants");
// These flags are specifically set to yield greatest chance of success
const BASE_TEMPLATE_GEN_ROW_CONFIG_FLAGS = {
    disablePageScaleNormalization: true,
    disableRowOffsetNormalization: true,
    disableRotationOffsetNormalization: true,
    enablePageBasisRowOffsetNormalization: false,
    enableTableBodyRowSplitting: false,
    enableNonTableBodyRows: true,
};
/**
 * Generates a template given a configuration of header field mappings and a
 * list of blocks. The template is a mapping of field names to their corresponding
 * x-min and x-max positions in the table.
 *
 * @param config The configuration of header field mappings.
 * @param blocks The list of blocks.
 */
const generateTemplates = (documentIdentifierHeaders, config, blocks) => __awaiter(void 0, void 0, void 0, function* () {
    const { rows: baseRows, yScale } = yield (0, rows_1.getRows)(null, null, blocks, documentIdentifierHeaders, {}, Object.assign(Object.assign({}, BASE_TEMPLATE_GEN_ROW_CONFIG_FLAGS), { enableTableBodyRowSplitting: config.templateSignatureGroups.some((group) => group.templateSignatures.length > 1) }));
    // Ensure rows are non-empty and sorted by y-position
    const rows = baseRows
        .filter((row) => row.length && /\w/.test(row.map(({ text }) => text).join(" ")))
        .sort((a, b) => _.meanBy(a, (block) => block.boundingBox.y) -
        _.meanBy(b, (block) => block.boundingBox.y));
    return config.templateSignatureGroups.map((group) => {
        const groupTemplateParts = group.templateSignatures.map((signature) => {
            if (signature.keyPlacement === graphql_types_1.TemplateKeyPlacement.INLINE_KEY) {
                throw new Error("INLINE_KEY placement not yet supported");
            }
            return generateTemplateFromHeaderKeys(signature.fieldConfigs, rows, yScale || 0.008);
        });
        return _.merge({}, ...groupTemplateParts);
    });
});
exports.generateTemplates = generateTemplates;
/**
 * Generates templates given a set of headers to key by.
 *
 * @param config
 * @param rows
 * @param yScale
 * @returns
 */
const generateTemplateFromHeaderKeys = (config, rows, yScale) => {
    const blocksById = _.keyBy(rows.flat(), (block) => block.id);
    const filteredRows = getFilteredRows(config, rows);
    // Get sets of header bounds - every detected set of headers is represented
    // as a list of x-min and x-max positions for each header in the set
    const bounds = getHeaderBounds(config, rows, filteredRows, yScale);
    // Choose template with the greatest vertical span - this is more likely to
    // include line-wrapped content which we want
    const templates = _.sortBy(bounds
        .flatMap((bound) => getTemplateForHeaderBounds(bound, filteredRows))
        .filter(utils_1.isNotNullAndNotUndefined), (template) => {
        const yMin = Math.min(...Object.values(template)
            .flat()
            .map((id) => blocksById[id].boundingBox.y));
        const yMax = Math.max(...Object.values(template)
            .flat()
            .map((id) => blocksById[id].boundingBox.y + blocksById[id].boundingBox.height));
        return yMin - yMax;
    });
    return templates[0];
};
/**
 * Attempts to find table header rows containing the headers in the template config.
 * If table header contains every header in the template config, the x-min and x-max
 * positions of each header are recorded.
 *
 * This logic does *not* use any Textract headers since those are not reliable enough -
 * rather, it does the following:
 *     - For each header, finds all sets of consecutive blocks that match the header text
 *     - Groups matched header blocks positionally by row
 *     - For each group of matched headers, ensure every header in the template config is
 *       present
 *     - Returns the x-min and x-max positions of each header in remaining groups
 *
 * @param config
 * @param rows
 * @param yScale
 * @returns
 */
const getHeaderBounds = (config, rows, filteredRows, yScale) => {
    // First, collect *all* sets of blocks that fully match the headers in the config,
    // independently of each other
    const headerBlockMatches = getHeaderBlockMatches(config, rows, yScale);
    // Group header blocks that are y-aligned (visually, group headers that
    // would appear in the same set of headers for a particular table)
    const groupedHeaderBlockMatches = [];
    while (headerBlockMatches.length > 0) {
        const item = headerBlockMatches.shift();
        if (!item)
            break;
        const group = [item];
        const currMinY = Math.min(...item[1].map((b) => b.boundingBox.y));
        const currMaxY = Math.max(...item[1].map((b) => b.boundingBox.y + b.boundingBox.height));
        let ind = 0;
        while (ind < headerBlockMatches.length) {
            const otherItem = headerBlockMatches[ind];
            const otherMinY = Math.min(...otherItem[1].map((b) => b.boundingBox.y));
            const otherMaxY = Math.max(...otherItem[1].map((b) => b.boundingBox.y + b.boundingBox.height));
            if (
            // Check if overlap between y-coordinates of entire header group
            (currMinY <= otherMaxY && currMaxY >= otherMinY) ||
                (otherMinY <= currMaxY && otherMaxY >= currMinY)) {
                group.push(otherItem);
                headerBlockMatches.splice(ind, 1);
            }
            else {
                ind++;
            }
        }
        groupedHeaderBlockMatches.push(group);
    }
    // Find all potential header boundaries - a boundary is defined as a continuous
    // axis where no blocks in filtered rows are present (e.g. intersect boundary)
    const potentialHeaderBounds = getPotentialHeaderBounds(groupedHeaderBlockMatches, rows, filteredRows, yScale);
    // Finally, for each group of header blocks, find the boundaries that most
    // closely hug the header blocks
    return groupedHeaderBlockMatches.map((group) => group.map(([field, blocks]) => {
        const xMinHeader = Math.min(...blocks.map((block) => block.boundingBox.x)) +
            constants_2.ALIGNMENT_OFFSET_THRESHOLD;
        const xMaxHeader = Math.max(...blocks.map((block) => block.boundingBox.x + block.boundingBox.width)) - constants_2.ALIGNMENT_OFFSET_THRESHOLD;
        const xMin = _.findLast(potentialHeaderBounds, (x) => x < xMinHeader) || 0;
        const xMax = _.find(potentialHeaderBounds, (x) => x > xMaxHeader) || Infinity;
        return { field, xMin, xMax, xMinHeader, xMaxHeader };
    }));
};
/**
 * For each header in the config, finds every set of consecutive blocks that match
 * the header text. The algorithm will match all the blocks within a row that it can,
 * then jump to the next row (y-aligned) and continue matching that row until it can,
 * then the next row, etc.
 *
 * @param config
 * @param rows
 * @param yScale
 * @returns
 */
const getHeaderBlockMatches = (config, rows, yScale) => {
    const headerBlockMatches = config.map(({ field, key: header }) => {
        // Given existing matched text and a column, gets longest chain of blocks
        // in row that match the header, and returns the column index of the last
        // block in the chain
        const getNextJ = (text, i, j) => {
            let nextJ = j + 1;
            let nextText = text;
            while (nextJ < rows[i].length &&
                header.startsWith(`${nextText} ${rows[i][nextJ].text}`) &&
                rows[i][nextJ].boundingBox.x -
                    (rows[i][nextJ - 1].boundingBox.x +
                        rows[i][nextJ - 1].boundingBox.width) <
                    yScale) {
                nextText = `${nextText} ${rows[i][nextJ].text}`;
                nextJ++;
            }
            if (nextText !== text) {
                return {
                    nextJ,
                    text: nextText,
                };
            }
            return {
                nextJ: null,
                text,
            };
        };
        // Given existing matched text and a row, checks if there exists a block
        // in the next row that matches the header, and returns the column index
        // of that block if so
        const getNextI = (text, i, xMin, xMax) => {
            const nextI = i + 1;
            const nextJ = rows[nextI].findIndex((block) => block.boundingBox.x + block.boundingBox.width >= xMin &&
                block.boundingBox.x <= xMax &&
                header.startsWith(`${text} ${block.text}`));
            if (nextJ === -1) {
                return {
                    nextI: null,
                    nextJ: null,
                    nextText: text,
                };
            }
            return {
                nextI,
                nextJ,
                nextText: `${text} ${rows[nextI][nextJ].text}`,
            };
        };
        let i = 0;
        const allHeaderMatches = [];
        while (i < rows.length) {
            let j = 0;
            while (j < rows[i].length) {
                const text = rows[i][j].text;
                if (header.startsWith(text)) {
                    let nextText = text;
                    let nextJ = j;
                    let nextI = i;
                    const matchedBlocks = [];
                    while (nextI !== null && nextJ !== null) {
                        matchedBlocks.push(...rows[nextI].slice(nextJ, nextJ + 1));
                        const originalNextI = nextI;
                        const originalNextJ = nextJ;
                        const { nextJ: innerNextJ, text: innerNextText } = getNextJ(nextText, originalNextI, originalNextJ);
                        const xMin = Math.min(...rows[originalNextI]
                            .slice(originalNextJ, innerNextJ || originalNextJ + 1)
                            .map((block) => block.boundingBox.x));
                        const xMax = Math.max(...rows[originalNextI]
                            .slice(originalNextJ, innerNextJ || originalNextJ + 1)
                            .map((block) => block.boundingBox.x + block.boundingBox.width));
                        ({ nextI, nextJ, nextText } = getNextI(innerNextText, originalNextI, xMin, xMax));
                        if (innerNextJ !== null) {
                            matchedBlocks.push(...rows[originalNextI].slice(originalNextJ + 1, innerNextJ));
                        }
                    }
                    if (matchedBlocks.map((block) => block.text).join(" ") === header) {
                        allHeaderMatches.push(matchedBlocks);
                    }
                }
                j++;
            }
            i++;
        }
        return [constants_1.ADEM_FIELDS_METADATA[field].header, allHeaderMatches];
    });
    return headerBlockMatches.flatMap(([field, matches]) => matches.map((blocks) => [field, blocks]));
};
const getPotentialHeaderBounds = (groupedHeaderBlockMatches, rows, filteredRows, yScale) => {
    // Find row with blocks taking up the most area - this will increase our
    // odds of finding a legitimate boundary
    const rowsByWidthDesc = _.sortBy(filteredRows, (row) => coalesceConsecutiveBlockRegions(row).cumulativeWidth);
    const longestRow = rowsByWidthDesc[rowsByWidthDesc.length - 1];
    // The x-min and x-max positions of potential header boundaries - a boundary
    // is defined as a continuous axis where no blocks are present
    const potentialHeaderBounds = [];
    // First find potential boundary using longest row
    potentialHeaderBounds.push(...getBoundsForRow(longestRow, filteredRows, yScale));
    // Then add potential boundaries using header groups
    groupedHeaderBlockMatches.forEach((group) => {
        const headerRows = rows.filter((row) => row.some((block) => group.some(([, blocks]) => blocks.includes(block))));
        // Sort by absolute x-position (not just "reading" order)
        const sortedHeaderBlocks = headerRows
            .flat()
            .sort((a, b) => a.boundingBox.x - b.boundingBox.x);
        // For each group of header blocks, find its neighboring blocks and see
        // if the midpoint between the preceding and succeeding blocks (each) is a
        // potential header boundary
        group.forEach(([, blocks]) => {
            const xMin = Math.min(...blocks.map((b) => b.boundingBox.x));
            const xMax = Math.max(...blocks.map((b) => b.boundingBox.x + b.boundingBox.width));
            // Find previous neighboring block
            const prev = _.findLast(sortedHeaderBlocks, (block) => !blocks.some((headerBlock) => headerBlock.id === block.id) &&
                block.boundingBox.x + block.boundingBox.width < xMin);
            // Find next neighboring block
            const next = _.find(sortedHeaderBlocks, (block) => !blocks.some((headerBlock) => headerBlock.id === block.id) &&
                block.boundingBox.x > xMax);
            if (prev &&
                !potentialHeaderBounds.some((x) => x > prev.boundingBox.x + prev.boundingBox.width && x < xMin)) {
                const prevMidpoint = (prev.boundingBox.x + prev.boundingBox.width + xMin) / 2;
                if (filteredRows.every((row) => !hasBlockOverlappingYAxis(row, prevMidpoint))) {
                    potentialHeaderBounds.push(prevMidpoint);
                }
            }
            if (next &&
                !potentialHeaderBounds.some((x) => x > xMax && x < next.boundingBox.x)) {
                const nextMidpoint = (next.boundingBox.x + xMax) / 2;
                if (filteredRows.every((row) => !hasBlockOverlappingYAxis(row, nextMidpoint))) {
                    potentialHeaderBounds.push(nextMidpoint);
                }
            }
        });
        return headerRows;
    });
    // Ensure boundaries sorted left-to-right
    potentialHeaderBounds.sort((a, b) => a - b);
    return potentialHeaderBounds;
};
const hasBlockOverlappingYAxis = (row, yAxis) => row.some((block) => block.boundingBox.x + block.boundingBox.width > yAxis &&
    block.boundingBox.x < yAxis);
const getFieldTypeRegex = (field) => {
    switch (field) {
        case graphql_types_1.AdemField.POLICY_NUMBER:
            return "([A-Z0-9]){3,}?";
        case graphql_types_1.AdemField.CLIENT:
            return "\\w{3,}";
        case graphql_types_1.AdemField.DATE:
            return "\\w{2,}";
        case graphql_types_1.AdemField.LINE:
        case graphql_types_1.AdemField.CONTINGENT:
        case graphql_types_1.AdemField.TRANSACTION_CODE:
        case graphql_types_1.AdemField.CUSTOM:
            return "\\w+";
        case graphql_types_1.AdemField.RATE:
        case graphql_types_1.AdemField.MEMBERS:
            return "[0-9]+";
        case graphql_types_1.AdemField.PREMIUM:
        case graphql_types_1.AdemField.NET_PREMIUM:
        case graphql_types_1.AdemField.COMMISSION:
            return "([0-9]\\.)+[0-9]+";
        default:
            return (0, utils_1.assertUnreachableValue)(field);
    }
};
/**
 * Finds all rows we have higher confidence to be actual commission rows given
 * RegExp pattern derived from the config. This is to avoid noise with non-commission
 * rows impacting the header boundary detection.
 *
 * @param config
 * @param rows
 * @returns
 */
const getFilteredRows = (config, rows) => {
    const regex = config
        .map(({ field, overrideRegex }) => overrideRegex || getFieldTypeRegex(field))
        .join(".+?");
    const re = new RegExp(regex);
    return rows.filter((row) => {
        const rowText = row.map(({ text }) => text).join(" ");
        return /\d+/.test(rowText) && re.test(rowText);
    });
};
/**
 * Given a row taking up the longest cumulative width, attempts to find potential
 * boundaries using midpoints of longest row blocks gaps.
 *
 * @param longestRow
 * @param otherRows
 * @param yScale
 * @param attempts
 * @returns
 */
const getBoundsForRow = (longestRow, otherRows, yScale, attempts = 3) => {
    const potentialBounds = [];
    const intersectingRowIndices = [];
    const coalescedBlockBounds = _.sortBy(coalesceConsecutiveBlockRegions(longestRow).regions, (a) => a.boundingBox.x);
    for (let colInd = 0; colInd < coalescedBlockBounds.length - 1; colInd++) {
        const curr = coalescedBlockBounds[colInd];
        const next = coalescedBlockBounds[colInd + 1];
        const x = (curr.boundingBox.x + curr.boundingBox.width + next.boundingBox.x) / 2;
        if (x - (curr.boundingBox.x + curr.boundingBox.width) > yScale / 2) {
            const intersectingRowIndices = getIntersectingRowIndices(x, otherRows);
            if (intersectingRowIndices.length === 0) {
                potentialBounds.push(x);
            }
            else {
                intersectingRowIndices.push(...intersectingRowIndices);
            }
        }
    }
    if (_.uniq(intersectingRowIndices).length > 0 && attempts > 0) {
        const otherAttemptRowBounds = getBoundsForRow(otherRows[intersectingRowIndices[0]], otherRows.filter((_, rowInd) => rowInd !== intersectingRowIndices[0]), yScale, attempts - 1);
        if (otherAttemptRowBounds.length > potentialBounds.length) {
            return otherAttemptRowBounds;
        }
    }
    return potentialBounds;
};
const getIntersectingRowIndices = (x, otherRows) => otherRows
    .map((row, rowIndex) => row.some((block) => block.boundingBox.x + block.boundingBox.width > x &&
    block.boundingBox.x < x)
    ? rowIndex
    : null)
    .filter(utils_1.isNotNullAndNotUndefined);
/**
 * Combines all consecutive blocks in a row into regions to form a new row
 * of "combined" blocks.
 */
const coalesceConsecutiveBlockRegions = (blocks) => {
    const rowStartEnds = blocks
        .flatMap((block) => [
        {
            id: block.id,
            x: block.boundingBox.x,
        },
        {
            id: block.id,
            x: block.boundingBox.x + block.boundingBox.width,
        },
    ])
        .sort((a, b) => a.x - b.x);
    const regions = [];
    let colInd = 0;
    let cumulativeWidth = 0;
    while (colInd < rowStartEnds.length - 1) {
        const curr = rowStartEnds[colInd];
        const ids = [curr.id];
        let innerColInd = colInd + 1;
        while (ids.length > 0 && innerColInd < rowStartEnds.length) {
            if (ids.includes(rowStartEnds[innerColInd].id)) {
                _.remove(ids, (id) => id === rowStartEnds[innerColInd].id);
            }
            else {
                ids.push(rowStartEnds[innerColInd].id);
            }
            innerColInd++;
        }
        regions.push({
            boundingBox: {
                x: curr.x,
                width: rowStartEnds[innerColInd - 1].x - curr.x,
            },
        });
        cumulativeWidth += rowStartEnds[innerColInd - 1].x - curr.x;
        colInd = innerColInd;
    }
    return { regions: regions, cumulativeWidth: cumulativeWidth };
};
/**
 * Given a set of header bounds, finds content of rows within those bounds. Any
 * rows with every header fully matched are returned.
 *
 * @param bounds
 * @param rows
 * @returns
 */
const getTemplateForHeaderBounds = (bounds, rows) => {
    const rowsWithTemplate = rows.filter((row) => bounds.every((bound) => row.some((block) => blockWithinBounds(block, bound))));
    if (!rowsWithTemplate)
        return;
    const minBlockGap = Math.min(...rowsWithTemplate.map((row) => Math.min(...row.slice(1).map((currBlock, ind) => {
        const prevBlock = row[ind];
        if (currBlock.boundingBox.y >
            prevBlock.boundingBox.y + prevBlock.boundingBox.height) {
            // Only consider block gaps for blocks in same y-aligned region
            return Infinity;
        }
        return currBlock.boundingBox.x - row[ind].boundingBox.x;
    }))));
    return rowsWithTemplate.map((rowWithTemplate) => Object.fromEntries(bounds.map((bound) => {
        const blockWithMostHeaderOverlap = _.maxBy(rowWithTemplate, (block) => Math.min(bound.xMaxHeader, block.boundingBox.x + block.boundingBox.width) - Math.max(bound.xMinHeader, block.boundingBox.x));
        if (!blockWithMostHeaderOverlap)
            throw new Error("No block found");
        const startInd = rowWithTemplate.indexOf(blockWithMostHeaderOverlap);
        // Ensure that even if blocks are technically within header bounds,
        // they do not exceed the maximum block gap
        const matchedBlocks = [blockWithMostHeaderOverlap.id];
        for (let i = startInd - 1; i >= 0; i--) {
            const block = rowWithTemplate[i];
            if (blockWithinBounds(block, bound) &&
                block.boundingBox.x + block.boundingBox.width + 2 * minBlockGap >=
                    rowWithTemplate[i + 1].boundingBox.x) {
                matchedBlocks.unshift(block.id);
            }
            else {
                break;
            }
        }
        for (let i = startInd + 1; i < rowWithTemplate.length; i++) {
            const block = rowWithTemplate[i];
            if (blockWithinBounds(block, bound) &&
                block.boundingBox.x <
                    rowWithTemplate[i - 1].boundingBox.x +
                        rowWithTemplate[i - 1].boundingBox.width +
                        2 * minBlockGap) {
                matchedBlocks.push(block.id);
            }
            else {
                break;
            }
        }
        return [bound.field, matchedBlocks];
    })));
};
const blockWithinBounds = (block, bound) => block.boundingBox.x >= bound.xMin &&
    block.boundingBox.x + block.boundingBox.width <= bound.xMax;
