import { useCallback, useEffect, useMemo, useState } from "react";
import { Option } from "../../components/Select";
import useImportPeriod from "../../hooks/useImportPeriod";
import { PeriodStatus, usePeriodsPageQuery } from "@comulate/graphql-types";
import { getPeriodName } from "../../src/formatting";
import { Period } from "../../components/Appbar/PeriodSelector/types";
import logger from "../../src/logger";

export type PeriodOption = Option & { status: PeriodStatus };

/**
 * Hook to manage the period options state selection in the dashboard
 */
export default function usePeriodOptions() {
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodOption | null>(
    null
  );

  const { data: periodsData } = usePeriodsPageQuery();
  const { importPeriod } = useImportPeriod();

  const allPeriodsOptions: PeriodOption[] = useMemo(() => {
    return (
      periodsData?.periods
        .map((period) => ({ ...period, value: getPeriodName(period) }))
        .sort((a, b) => (a.rangeStart > b.rangeStart ? -1 : 1)) ?? []
    );
  }, [periodsData]);

  const changePeriod = useCallback(
    (allPeriodsOptions: PeriodOption[], importPeriod: Period) => {
      const userSelectedPeriod =
        allPeriodsOptions.find(
          (option: PeriodOption) => option.id === importPeriod.id
        ) ?? null;

      if (!userSelectedPeriod) {
        logger.error(
          "Invariant violation: a user must have a selected period",
          {
            allPeriodsOptions,
            importPeriod,
          }
        );
      }

      setSelectedPeriod(userSelectedPeriod);
    },
    []
  );

  useEffect(() => {
    if (importPeriod && allPeriodsOptions.length) {
      changePeriod(allPeriodsOptions, importPeriod);
    }
  }, [allPeriodsOptions, changePeriod, importPeriod]);

  useEffect(() => {
    if (selectedPeriod === null && importPeriod && allPeriodsOptions.length) {
      changePeriod(allPeriodsOptions, importPeriod);
    }
  }, [allPeriodsOptions, importPeriod, changePeriod, selectedPeriod]);

  return [selectedPeriod, setSelectedPeriod, allPeriodsOptions] as const;
}
