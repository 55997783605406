import { useCallback, useMemo } from "react";
import logger from "../src/logger";
import { useLoggedInUser } from "../components/LoggedInUserProvider";

/**
 * NOTE: Setting localStorage itself will NOT trigger a re-render. It is the callers
 * responsibility to ensure that localStorage is set in a way that triggers a re-render.
 *
 * useLocalStorage returns a getter and setter to access browser's local storage.
 * Note that storage keys are global, so ensure that it is specific to the
 * app/page/workflow that is using it.
 *
 * Each key is scoped to the organization ID, so that different organizations can have
 * different data stored in local storage.
 *
 * This function JSON stringifies its input, and parse its output as JSON. If JSON
 * parsing fails, it returns null.
 * @param storageKey The key of browser's local storage to find the data. This is global
 *  across the browser, so be mindful not to clobber another app's data.
 */
export function useLocalStorage<T>(storageKey: string): {
  localStorageState: T | null;
  setLocalStorageState: (item: T) => void;
} {
  const { organization } = useLoggedInUser();

  const generatedKey = useMemo(
    () => (organization?.id ? `${storageKey}-${organization.id}` : null),
    [storageKey, organization?.id]
  );

  const setLocalStorageState = useCallback(
    (item: T) => {
      if (!generatedKey) return; // Fail silently if key is not generated

      localStorage.setItem(generatedKey, JSON.stringify(item));
    },
    [generatedKey]
  );

  const state: T | null = useMemo(() => {
    if (!generatedKey) return null; // Fail silently if key is not generated

    const raw = localStorage.getItem(generatedKey);
    if (raw === null) return null;
    try {
      return JSON.parse(raw);
    } catch (e) {
      logger.error(
        `failed to parse state '${raw}' at key: '${generatedKey}': ${e}`
      );
      return null;
    }
  }, [generatedKey]);

  return { localStorageState: state, setLocalStorageState };
}
