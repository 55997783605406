"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SheetBatchParser = void 0;
const sheetParser_1 = require("./sheetParser");
const _ = __importStar(require("lodash"));
const XLSX = __importStar(require("xlsx"));
const HEADER_DELIMITER = "-*-";
class SheetBatchParser {
    constructor(batchSize, sheetParsers) {
        /** Only return first sheet */
        this.getWorkSheet = () => this.sheetParsers[0].getWorkSheet();
        this.getSheetDetections = () => {
            const { headerRowIndexOffset, headerTypeMapping } = this.sheetParsers[0].getSheetDetections();
            const inferredExcludedRowIndices = this.sheetParsers.flatMap((sheetParser) => {
                const { inferredExcludedRowIndices } = sheetParser.getSheetDetections();
                return inferredExcludedRowIndices.map((rowInd) => rowInd + rowInd * this.batchSize);
            });
            return {
                headerRowIndexOffset,
                inferredExcludedRowIndices,
                headerTypeMapping,
            };
        };
        this.batchSize = batchSize;
        this.sheetParsers = sheetParsers;
    }
    getHeaderRowIndex() {
        return this.sheetParsers[0].getHeaderRowIndex();
    }
    getRawSheetJSON() {
        return this.sheetParsers.flatMap((sheetParser) => sheetParser.getRawSheetJSON());
    }
    getSheetJSON() {
        return this.sheetParsers.flatMap((sheetParser) => sheetParser.getSheetJSON());
    }
    getRowsWithInvalidData(fieldMapper, contingentRevenueMapping, filterConfig, billingMode, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const invalidRows = [];
            for (let ind = 0; ind < this.sheetParsers.length; ind++) {
                const sheetParser = this.sheetParsers[ind];
                const sheetInvalidRows = yield sheetParser.getRowsWithInvalidData(fieldMapper, contingentRevenueMapping, filterConfig, billingMode, options);
                invalidRows.push(...sheetInvalidRows.map((invalidRow) => (Object.assign(Object.assign({}, invalidRow), { rowIndex: invalidRow.rowIndex + ind * (this.batchSize - 1) }))));
            }
            return invalidRows;
        });
    }
    getRowValues(rowIndex) {
        const sheetParserInd = Math.floor(rowIndex / this.batchSize);
        const rowIndexInSheet = rowIndex % this.batchSize;
        return this.sheetParsers[sheetParserInd].getRowValues(rowIndexInSheet);
    }
    getColumnValue(rowIndex, columnName) {
        const sheetParserInd = Math.floor(rowIndex / this.batchSize);
        const rowIndexInSheet = rowIndex % this.batchSize;
        return this.sheetParsers[sheetParserInd].getColumnValue(rowIndexInSheet, columnName);
    }
    getRowIndices(rowOptions = {}) {
        return this.sheetParsers.flatMap((sheetParser, sheetParserInd) => sheetParser
            .getRowIndices(rowOptions)
            .map((rowIndex) => rowIndex + sheetParserInd * this.batchSize));
    }
    getColumnNames() {
        return this.sheetParsers[0].getColumnNames();
    }
    static fromParsers(batchSize, allSheetParsers, _a = {}) {
        var { metadata } = _a, options = __rest(_a, ["metadata"]);
        return __awaiter(this, void 0, void 0, function* () {
            const sheetParserGroups = _.groupBy(allSheetParsers, (sheetParser) => sheetParser.getHeaders().join(HEADER_DELIMITER));
            const allHeaders = Object.keys(sheetParserGroups).flatMap((group) => group.split(HEADER_DELIMITER));
            const allSheetRowJSON = Object.values(sheetParserGroups).flatMap((group) => {
                const rowJSON = group.flatMap((sheetParser) => {
                    const { headerRowIndexOffset } = sheetParser.getSheetDetections();
                    const sheetJson = XLSX.utils.sheet_to_json(sheetParser.getWorkSheet(), {
                        defval: null,
                        blankrows: true,
                        raw: false,
                        rawNumbers: true,
                        range: headerRowIndexOffset,
                    });
                    return sheetJson.map((row) => (Object.assign({ Sheet: sheetParser.getSheetName() }, Object.fromEntries(allHeaders.map((header) => [header, row[header]])))));
                });
                return rowJSON;
            });
            const allCombinedSheetParsers = yield Promise.all(
            // Subtract 1 from batchSize to account for the header row being added
            _.chunk(allSheetRowJSON, batchSize - 1).map((batchJSON) => __awaiter(this, void 0, void 0, function* () {
                return yield sheetParser_1.SheetParser.fromTabular(batchJSON, Object.assign({ skipInferredRowExclusion: true, metadata }, options));
            })));
            return new SheetBatchParser(batchSize, allCombinedSheetParsers);
        });
    }
}
exports.SheetBatchParser = SheetBatchParser;
