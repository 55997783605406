import { useState } from "react";
import { Button } from "../../Button";
import InlineLoader from "../../InlineLoader";
import ModalWithContent from "../../ModalWithContent";
import { Period } from "./types";
import { getPeriodName } from "../../../src/formatting";

const UnlockPeriodModal = ({
  selectedPeriodToUnlock,
  open,
  setOpen,
  onConfirm,
}: {
  selectedPeriodToUnlock: Period | null;
  open: boolean;
  setOpen: (val: boolean) => void;
  onConfirm: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);

  const unlockPrompt = selectedPeriodToUnlock
    ? `Are you sure you’d like to unlock the ${getPeriodName(
        selectedPeriodToUnlock
      )} period?`
    : "";

  return (
    <ModalWithContent
      title="Unlock Period"
      showCloseButton
      body={
        <div className="text-base leading-6 font-normal text-zinc-700 w-[600px]">
          {unlockPrompt && <div className="mb-7">{unlockPrompt}</div>}
          <div className="mb-7">
            When unlocked, new statements, deposits and payables can be imported
            to this period by your team.
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end space-x-2">
          <Button colorScheme="white" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              setOpen(false);
              await onConfirm();
              setLoading(false);
            }}
          >
            {loading && <InlineLoader className="mr-2" />}
            Unlock period
          </Button>
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  );
};

export default UnlockPeriodModal;
