import { NextApiRequest, NextApiResponse } from "next";
import logger from "../../../src/logger";

/**
 * This version number should be updated when breaking changes are made. Users whose locally stored
 * version if less than the latest version will be prompted to refresh the page.
 * VERSION SHOULD ONLY EVER INCREASE
 */
export const LATEST_APP_VERSION = 101;
export default async function handler(
  req: NextApiRequest,
  res: NextApiResponse<boolean>
) {
  if (req.method === "GET") {
    const { version } = req.query;
    if (!version) {
      logger.warn(
        `userVersion not provided to requires-refresh endpoint: ${version}`
      );
      res.status(400).end();
    } else {
      const userVersionParsed = parseInt(version as string);
      const needsRefresh = userVersionParsed < LATEST_APP_VERSION;
      if (needsRefresh) {
        logger.info(`Refresh required for user with version: ${version}`);
      }
      res.status(200).json(needsRefresh);
    }
  } else {
    res.status(405).end();
  }
}
