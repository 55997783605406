import { useState, useMemo } from "react";
import { Button } from "../../Button";
import InlineLoader from "../../InlineLoader";
import ModalWithContent from "../../ModalWithContent";
import { Period } from "./types";
import { PeriodStatus } from "@comulate/graphql-types";
import { getPeriodName } from "../../../src/formatting";

const LockPeriodModal = ({
  periods,
  selectedPeriod,
  open,
  setOpen,
  onConfirm,
  nextPeriodDate,
}: {
  periods: Period[];
  selectedPeriod: Period;
  open: boolean;
  setOpen: (val: boolean) => void;
  onConfirm: () => Promise<void>;
  nextPeriodDate: string;
}) => {
  const [loading, setLoading] = useState(false);

  const openNextPeriodOnLock = useMemo(
    () =>
      periods.filter(({ status }) => status === PeriodStatus.OPEN).length === 1,
    [periods]
  );

  const existingNextPeriod = useMemo(() => {
    const period = periods?.find(
      (p) => p.status === PeriodStatus.OPEN && p.id !== selectedPeriod.id
    );
    return period ? getPeriodName(period) : null;
  }, [periods, selectedPeriod]);

  const formattedSelectedPeriod = useMemo(
    () => getPeriodName(selectedPeriod),
    [selectedPeriod]
  );

  const modalTitle = `Lock Period${
    openNextPeriodOnLock ? " and Open Next" : ""
  }`;

  const modalBodyFirstMessage = `Are you sure you'd like to lock the ${formattedSelectedPeriod} period for your team${
    openNextPeriodOnLock
      ? ` and create a new period for ${nextPeriodDate}?`
      : "?"
  }`;

  const modalBody = `This will move move your teammates on ${formattedSelectedPeriod} to ${
    existingNextPeriod ?? nextPeriodDate
  }.`;

  return (
    <ModalWithContent
      title={modalTitle}
      showCloseButton
      body={
        <div className="text-base leading-6 font-normal text-zinc-700 w-[600px]">
          <div className="mb-7">{modalBodyFirstMessage}</div>
          <div className="mb-7">{modalBody}</div>
        </div>
      }
      footer={
        <div className="flex justify-end space-x-2">
          <Button colorScheme="white" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              setOpen(false);
              await onConfirm();
              setLoading(false);
            }}
          >
            {loading && <InlineLoader className="mr-2" />}
            Lock Period
          </Button>
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  );
};

export default LockPeriodModal;
